let reportException;
import Honeybadger from "@honeybadger-io/js";

const apiKey = "55f170f4";
const environment = document.querySelector("meta[name='environment']")?.content || "test";
const revision = document.querySelector("meta[name='revision']")?.content || "main";
const debug = false;
const isSignedIn = () => document.querySelector("meta[name='signed-in']")?.content || "unknown";
const currentUserId = () => document.querySelector("meta[name='current-user-id']")?.content;

// https://docs.honeybadger.io/lib/javascript/guides/using-source-maps/#improving-error-grouping-and-linking-to-source-code
//
// Looks like everything starts with `webpack://dockspot/.`, e.g. Stimulus says:
// webpack://dockspot/./node_modules/@hotwired/stimulus/dist/stimulus.js:1668:16:in `connect'
const projectRoot = "webpack://dockspot/./"

Honeybadger.configure({
  debug,
  apiKey,
  environment,
  revision,
  projectRoot,
  breadcrumbsEnabled: true,
  maxErrors: 20,
  tags: ["frontend"]
});

Honeybadger.beforeNotify(function(notice) {
  // plugins / extensions
  if (/__gCrWeb\.edgeTranslate/.test(notice.message)) { return false; }
  if (/__gCrWeb\.instantSearch/.test(notice.message)) { return false; }
  if (/safari-extension/.test(notice.message)) { return false; }
  if (/webkitExitFullScreen/.test(notice.message)) { return false; }
  if (/a\[b\]\.target\.className\.indexOf\(Zb\)/.test(notice.message)) { return false; }
  if (/window\.webkit\.messageHandlers/.test(notice.message)) { return false; }
  if (/undefined is not an object \(evaluating 'a\.L'\)/.test(notice.message)) { return false; }
  if (/_AutofillCallbackHandler/.test(notice.message)) { return false; }

  // Twilio
  if (/UnhandledPromiseRejectionWarning: disconnected/.test(notice.message)) { return false; }
  if (/UnhandledPromiseRejectionWarning: n: disconnected/.test(notice.message)) { return false; }

  // Network errors
  if (/AbortError: Fetch is aborted/.test(notice.message)) { return false; }
  if (/AbortError: The user aborted a request/.test(notice.message)) { return false; }
  if (/TypeError: NetworkError when attempting to fetch resource/.test(notice.message)) { return false; }
  if (/AjaxNetworkError/.test(notice.name)) { return false; }
  if (/AjaxUnauthorizedResponseError/.test(notice.name)) { return false; }
  if (/UnhandledPromiseRejectionWarning:\sObject\sNot\sFound\sMatching\sId:/.test(notice.message)) { return false; }

  // Google Maps
  if (/maps\.googleapis\.com/.test(notice.stack)) { return false; }
  if (/b\.getBoundingClientRect/.test(notice.message)) { return false; }

  // Accessibe
  if (/acsbapp\.com/.test(notice.stack)) { return false; }
  if (/acsbJS is not defined/.test(notice.message)) { return false; }
  if (/Can't find variable: acsbJS/.test(notice.message)) { return false; }

  try { notice.context.signedIn ??= isSignedIn() } catch { /* no-op */ }
  try { notice.context.userId ??= currentUserId() } catch { /* no-op */ }
});

// See documentation for a list of options:
// https://docs.honeybadger.io/lib/javascript/guides/reporting-errors/#additional-options
export default reportException = function(error, options) {
  Honeybadger.notify(error, options);
};

Honeybadger.addBreadcrumb('Page loaded', {
  category: 'navigation',
  metadata: window.location
});
